import React from "react";
import { AcademicCapIcon } from "@heroicons/react/solid";
import { certifications } from '../data';

export default function Certifications() {
    return (<section id="certifications">
        <div className="container px-5 py-10 mx-auto">
            <div className="mb-20 text-center">
                <AcademicCapIcon className="inline-block w-10 mb-4" />
                <h1 className="mb-4 text-3xl font-medium text-white sm:text-4xl title-font">
                    Certifications
                </h1>
                <p className="mx-auto text-base leading-relaxed xl:w-2/4 lg:w-3/4">
                    I am certified in following technologies
                </p>
            </div>
            <div className="flex flex-wrap -mx-2 lg:w-4/5 sm:mx-auto sm:mb-2">
                {certifications.map((certificate) => {
                    return (
                        <div className="w-full p-2 sm:w-1/2">
                            <div className="flex items-center justify-between h-full p-4 bg-gray-800 rounded">
                                <span className="font-medium text-white title-font">
                                    {certificate.name}
                                </span>
                                <img className="flex items-center w-32 h-32 p-4 bg-gray-800 rounded" src={certificate.logo} alt={certificate.name} />
                            </div>
                        </div>)
                })}
            </div>
        </div>
    </section>);
}