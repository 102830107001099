import './App.css';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Navbar from './components/NavBar';
import Certifications from './components/Certifications';

function App() {
  return (
    <main className="text-gray-400 bg-gray-900 body-font">
      <Navbar />      
      <About />
      <Home />
      <Skills />
      <Certifications />
      <Contact />
    </main>
  );
}

export default App;
