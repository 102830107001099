export const projects = [
    {
        title: "Hacker News!",
        subtitle: "Your daily tech news!",
        description:
            "Get access to the latest top from the world of technology ",
        image: "./HackerNewsVirrupakshaTech.png",
        /*link: "https://hackernews.virrupakshatech.in/",*/
        link: "https://hackernews-app-frontend-git-abhimanyu-kolakotla-dev.apps.sandbox-m2.ll9k.p1.openshiftapps.com/"
    },

    {
        title: "BMI Calculator!",
        subtitle: "An app to check your bmi index!",
        description:
            "Flutter app to check BMI index based on height, weight, age and sex",
        image: "./bmi-calc.png",
        link: "https://bmicalculator-git-abhimanyu-kolakotla-dev.apps.sandbox.x8i5.p1.openshiftapps.com/"
    },
    {
        title: "Xylophone!",
        subtitle: "A Xylophone to play around!",
        description:
            "Flutter app to display a xlyophone to play around for fun!",
        image: "./xylophone.png",
        link: "https://xylophone-flutter-git-abhimanyu-kolakotla-dev.apps.sandbox.x8i5.p1.openshiftapps.com/"
    },

];

export const skills = ["Java EE", "Python", "Dart", "JavaScript", "Spring framework", "Flutter",
    "React", "RestAPI", "Docker", "IBM Cloud", "AWS", "Azure Cloud", "Kubernetes", "Certified Kubernetes Application Developer",
    "OpenShift"];

export const certifications = [
    {name:'Certified Kubernetes Application Developer', logo:'./images/ckad-certified-kubernetes-application-developer.png'}, 
    {name: 'Microsoft Certified: Azure Fundamentals', logo: './images/microsoft-certified-azure-fundamentals.png'},
    {name: 'IBM Carbon Design System Developer Essentials - React', logo: './images/ibm-carbon-design-system-developer-essentials-react.png'},
    {name: 'IBM Certified Advocate - Cloud v1', logo: './images/ibm-certified-advocate-cloud-v1.6.png'},
]
